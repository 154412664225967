// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

const linkResolver = ({ node, key, value }) => doc => {
  // const linkResolver = doc => {
  console.log("linkResolver", doc)
  if (doc.type === "post") {
    return `/blog/${doc.uid}`
  }
  if (doc.type === "project") {
    return `/our-work/${doc.uid}`
  }
  if (doc.type === "category") {
    return `/our-work/${doc.uid}`
  }
  return "/"
}

module.exports = linkResolver
