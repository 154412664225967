import React from "react"
import Img from "gatsby-image"
import Reveal from "./reveal"

function CaseImage({ image, children, client }) {
  return (
    <div className="row row__image">
      <div className="col-12">
        <Reveal>
          <div className="embed-responsive embed-responsive-21by9">
            <div className="embed-responsive-item">
              <Img
                objectFit="cover"
                objectPosition="50% 50%"
                fluid={image}
                alt={`${client}: Chunk Creative Agency - Amsterdam | London`}
              />
              {children}
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  )
}

CaseImage.defaultProps = {}

CaseImage.propTypes = {}

export default CaseImage
