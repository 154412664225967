import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Reveal from "./reveal"

const StyledAuthor = styled.h4`
  letter-spacing: 4px;
  text-transform: uppercase;
`

const QuoteRow = styled.div`
  z-index: -1;
  position: relative;
`

function Quote({ author, content }) {
  return (
    <QuoteRow className="row row__quote justify-content-center">
      <div className="col-12 col-md-10 col-lg-8 position-relative">
        <Reveal>
          <div className="quote text-center">
            <h3 className="display-4 font-weight-light mb-2 mb-md-4">
              <div className="d-inline-block">“{content}”</div>
            </h3>
            <StyledAuthor className="h6">{author}</StyledAuthor>
          </div>
        </Reveal>
      </div>
    </QuoteRow>
  )
}

Quote.defaultProps = {}

Quote.propTypes = {
  author: PropTypes.string.isRequired,
}

export default Quote
