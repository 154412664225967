import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import Reveal from "./reveal"

function ProjectVideo({ html }) {
  return (
    <div className="row row__image">
      <div className="col-12">
        <Reveal>
          <VisibilitySensor
            partialVisibility
            minTopValue="100"
            offset={{ top: 100 }}
          >
            <div className="embed-responsive embed-responsive-16by9">
              <div
                className="embed-responsive-item"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </VisibilitySensor>
        </Reveal>
      </div>
    </div>
  )
}

ProjectVideo.defaultProps = {}

ProjectVideo.propTypes = {}

export default ProjectVideo
