import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import styled from "@emotion/styled"
import Reveal from "./reveal"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -20px 0;
  > div {
    min-width: 300px;
    max-width: 350px;
    margin: 20px auto;
  }
  @media (min-width: 768px) {
    margin: -30px -20px;
    > div {
      margin: 30px 20px;
    }
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
`

function ProjectVideos({ videos }) {
  console.log("sda", videos)
  return (
    <div className="row row__image">
      <div className="col-12">
        <Reveal>
          <VisibilitySensor
            partialVisibility
            minTopValue="100"
            offset={{ top: 100 }}
          >
            <Wrapper>
              {videos.map((video, index) => (
                <div
                  className="embed-responsive embed-responsive-9by16"
                  key={index}
                >
                  <div
                    className="embed-responsive-item"
                    dangerouslySetInnerHTML={{ __html: video.video.html }}
                  />
                </div>
              ))}
            </Wrapper>
          </VisibilitySensor>
        </Reveal>
      </div>
    </div>
  )
}

ProjectVideos.defaultProps = {}

ProjectVideos.propTypes = {}

export default ProjectVideos
