import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Reveal from "./reveal"

const StyledH4 = styled.h4`
  letter-spacing: 4px;
`

const StyledH1 = styled.h1``

function ProjectIntro({ title, category }) {
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-12 col-md-10 col-lg-8">
          <Reveal>
            <StyledH4 className="h6 font-weight-normal text-uppercase mb-2">
              case | {category}
            </StyledH4>
            <StyledH1 className="display-2 font-weight-bolder">
              {title}
            </StyledH1>
          </Reveal>
        </div>
      </div>
    </>
  )
}

ProjectIntro.defaultProps = {
  title: "",
}

ProjectIntro.propTypes = {
  title: PropTypes.string,
}

export default ProjectIntro
