import React from "react"
import { Link } from "gatsby"
import ArrowRight from "./arrowRight"

function ProjectRole({ role, name }) {
  return (
    <div className="row row__text justify-content-center">
      <div className="col-12 col-md-10 col-lg-8">
        <div className="text-center">
          <h5 className="h3 font-weight-bolder">{role}</h5>
          <p className="h3">{name}</p>
          {1 === 0 && (
            <Link
              to="/about-us"
              className="no-underline d-inline-block black mt-2"
            >
              <span className="h2 circle gradient-border">
                <ArrowRight />
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

ProjectRole.defaultProps = {}

ProjectRole.propTypes = {}

export default ProjectRole
