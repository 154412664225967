import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import Reveal from "./reveal"

const StyledP = styled.p``

const TableRow = styled.div`
  position: relative;
  z-index: 1;
`

const ContentRow = styled.div``

const StyledLogo = styled(Img)`
  max-width: 180px;
`

function ProjectTable({
  title,
  subTitle,
  client,
  logo,
  deliverables,
  content,
}) {
  return (
    <>
      <TableRow className="row">
        <div className="col-12 col-md-4">
          <Reveal>
            <h2 className="h6 font-weight-bold mb-1 mb-md-3">Client</h2>
            <StyledLogo fluid={logo} alt={client} />
          </Reveal>
        </div>
        <div className="col-12 offset-md-1 col-md-5">
          <Reveal>
            <h2 className="h6 font-weight-bold mt-2 mt-md-0 mb-1 mb-md-3">
              Deliverables
            </h2>
            <ul className="list-unstyled list--deliverables">
              {deliverables &&
                deliverables.map(deliverable => (
                  <li className="badge badge-transparent" key={deliverable}>
                    {deliverable}
                  </li>
                ))}
            </ul>
          </Reveal>
        </div>
      </TableRow>
      <Reveal>
        <ContentRow className="row row__intro">
          <div className="offset-1 col-10 col-sm-8 offset-sm-2 offset-md-5 col-md-6 offset-lg-5 col-lg-5 position-relative text-center blob-2">
            <StyledP>{content}</StyledP>
          </div>
        </ContentRow>
      </Reveal>
    </>
  )
}

ProjectTable.defaultProps = {
  title: "",
}

ProjectTable.propTypes = {
  title: PropTypes.string,
}

export default ProjectTable
