import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Reveal from "./reveal"

const StyledP = styled.div``

const StyledH5 = styled.h5``

function ProjectText({ title, content }) {
  return (
    <div className="row row__text justify-content-center">
      <div className="col-12 col-md-10 col-lg-8">
        <div className="text-center">
          <Reveal>
            <StyledH5 className="h6 font-weight-bolder mb-2">{title}</StyledH5>
            <StyledP>{content}</StyledP>
          </Reveal>
        </div>
      </div>
    </div>
  )
}

ProjectText.defaultProps = {
  title: "",
}

ProjectText.propTypes = {
  title: PropTypes.string,
}

export default ProjectText
