import React, { useEffect, useRef, createRef } from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { withPreview } from "gatsby-source-prismic"
import Img from "gatsby-image"

import styled from "@emotion/styled"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Reveal from "../components/reveal"
import Project from "../components/project"

import CaseImage from "../components/caseImage"
import ProjectText from "../components/projectText"
import ProjectTable from "../components/projectTable"
import ProjectIntro from "../components/projectIntro"
import ProjectQuote from "../components/projectQuote"
import ProjectRole from "../components/projectRole"
import ProjectVideoEmbed from "../components/projectVideoEmbed"
import ProjectVideosEmbed from "../components/projectVideosEmbed"
import { linkResolver } from "../utils/linkResolver"

const Rounded = styled.div`
  width: 150vw;
  height: calc(175px + 5%);
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 150px);
  z-index: 1;
  background-color: #fff;
`

const StyledHero = styled(Hero)``

const Section = styled.section`
  position: relative;
  z-index: 1;
`

const NextSection = styled.section`
  margin: 0;
  padding: 5% 0 15%;
  @media (min-width: 768px) {
    padding: 5% 0 10%;
  }
`

export const PageQuery = graphql`
  query ProjectQuery($uid: String) {
    prismicProject(uid: { eq: $uid }) {
      data {
        title {
          raw
        }
        client {
          raw
        }
        client_logo {
          fluid(maxWidth: 500) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        description {
          raw
        }
        hero_image {
          fluid(maxWidth: 5000) {
            ...GatsbyPrismicImageFluid_withWebp
          }
          thumbnails {
            mobile {
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid_withWebp
              }
            }
          }
        }
        category {
          document {
            ... on PrismicCategory {
              data {
                name
              }
            }
          }
        }
        next_project {
          document {
            ... on PrismicProject {
              id
              uid
              data {
                title {
                  raw
                }
                client {
                  raw
                }
                hero_image {
                  fluid(maxWidth: 5000) {
                    ...GatsbyPrismicImageFluid_withWebp
                  }
                  thumbnails {
                    mobile {
                      fluid(maxWidth: 1600) {
                        ...GatsbyPrismicImageFluid_withWebp
                      }
                    }
                  }
                }
                client_logo {
                  fluid(maxWidth: 500) {
                    ...GatsbyPrismicImageFluid_withWebp
                  }
                }
                category {
                  document {
                    ... on PrismicCategory {
                      id
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }

        body {
          ... on PrismicProjectBodyProjectDetails {
            slice_label
            slice_type
            primary {
              deliverables {
                raw
              }
              content {
                raw
              }
            }
          }
          ... on PrismicProjectBodyImage {
            id
            slice_label
            slice_type
            primary {
              image {
                fluid(maxWidth: 5000) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
            }
          }
          ... on PrismicProjectBodyVideo {
            id
            slice_label
            slice_type
            primary {
              video {
                embed_url
                html
              }
            }
          }
          ... on PrismicProjectBodyQuote {
            id
            slice_label
            slice_type
            primary {
              author {
                raw
              }
              quote_title {
                raw
              }
              quote_title {
                raw
              }
            }
          }
          ... on PrismicProjectBodyText {
            id
            slice_label
            slice_type
            primary {
              content {
                raw
              }
              text_title {
                raw
              }
            }
          }
          ... on PrismicProjectBodyVideos {
            id
            items {
              aspect_ratio
              video {
                html
                embed_url
              }
            }
            slice_label
            slice_type
          }
        }
      }
    }
  }
`

const NextProject = ({ project }) => {
  return (
    <NextSection className="section--next bg-white position-relative">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <Reveal>
              <div className="display-2 font-weight-light black mb-1 mb-md-2 mb-lg-4">
                Next project
              </div>
            </Reveal>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Project project={project}></Project>
          </div>
        </div>
      </div>
    </NextSection>
  )
}

// Sort and display the different slice options
const ProjectSlices = ({ client, logo, slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "project_details": {
          const deliverables = slice.primary.deliverables.raw.map(
            deliverable => deliverable.text
          )
          return (
            <ProjectTable
              key={`section_${index}`}
              client={client}
              logo={logo}
              content={RichText.asText(slice.primary.content.raw)}
              deliverables={deliverables}
            />
          )
        }
        case "image":
          return (
            <CaseImage
              key={`section_${index}`}
              image={slice.primary.image.fluid}
              client={client}
            />
          )
        case "video":
          return (
            <ProjectVideoEmbed
              key={`section_${index}`}
              client={client}
              html={slice.primary.video.html}
            />
          )

        case "videos":
          return (
            <ProjectVideosEmbed
              key={`section_${index}`}
              client={client}
              videos={slice.items}
            />
          )

        case "text":
          return (
            <ProjectText
              key={`section_${index}`}
              client={client}
              title={RichText.asText(slice.primary.text_title.raw)}
              content={RichText.render(slice.primary.content.raw, linkResolver)}
            />
          )
        case "quote":
          return (
            <ProjectQuote
              key={`section_${index}`}
              client={client}
              author={RichText.asText(slice.primary.author.raw)}
              content={RichText.asText(slice.primary.quote_title.raw)}
            />
          )
        case "role":
          return <ProjectRole key={`section_${index}`} />

        default:
          return null
      }
    })()
    return res
  })
}

const ProductHero = ({ project }) => {
  const heroRef = createRef(null)
  const sectionRef = useRef(null)

  const sources = [
    project.hero_image.thumbnails.mobile.fluid,
    {
      ...project.hero_image.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  useEffect(() => {
    gsap.to(heroRef.current, {
      yPercent: 50,
      ease: "none",
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
    return () => {}
  }, [sectionRef, heroRef])

  return (
    <section
      className="section-hero--project position-relative"
      ref={sectionRef}
    >
      <StyledHero
        ref={heroRef}
        img={
          <Img
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={sources}
            alt={`${project.client.text}: ${project.title.text}`}
          />
        }
      ></StyledHero>
      <Rounded></Rounded>
    </section>
  )
}

// Display the title, date, and content of the Project
const ProductBody = ({ project }) => {
  const client = RichText.asText(project.client.raw)
  const title = RichText.asText(project.title.raw)
  const category = project.category.document.data.name || ""

  return (
    <>
      <ProductHero project={project} />

      <Section className="bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ProjectIntro title={title} category={category}></ProjectIntro>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="project__rows">
                <ProjectSlices
                  client={client}
                  logo={project.client_logo.fluid}
                  slices={project.body}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export const ProjectTemplate = ({ data }) => {
  if (!data) return null
  // Define the Project content returned from Prismic
  const project = data.prismicProject.data
  if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
  }

  return (
    <>
      <SEO
        title={`${RichText.asText(project.client.raw)}: ${RichText.asText(
          project.title.raw
        )}`}
        description={`${RichText.asText(project.description.raw)}`}
        image={project.hero_image.fluid.src}
      />
      <ProductBody project={project} />
      {project.next_project && (
        <NextProject project={project.next_project.document}></NextProject>
      )}
    </>
  )
}

export default withPreview(ProjectTemplate)
